import { useState } from 'react';
import Slider from 'react-slick';
import steps from './img/steps.png';
import usps from './img/usps.png';
import guarantee from './img/guarantee.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Default.sass';
import { getPriceWithCurrency, getSavingsPercent } from '../../../../utils/helpers';
import ProductSelector from '../../ProductSelector/ProductSelector';

export default function Default({ specialOffer, onSubmit, offer }) {
  const mergedProductsData = specialOffer.productsData.map((product) => {
    const productDetail = specialOffer.productDetails[product.id];

    return {
      ...product,
      ...productDetail,
    };
  });

  const [selectedProduct, setSelectedProduct] = useState(mergedProductsData[0]);
  const discount = getSavingsPercent(selectedProduct.oldPrice, selectedProduct.price);

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const gallerySettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    adaptiveHeight: true,
  };

  const thumbsSettings = {
    slidesToShow: specialOffer?.gallery?.length,
    slidesToScroll: 1,
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    arrows: false,
  };

  function handleProductSelect(product) {
    setSelectedProduct(product);
  }

  return (
    <>
      <header className='page-header'>
        <div className='container'>
          <div className='page-header-inner'>
            <div className='page-header__hurry'>
              <b>HURRY!</b> Only 42 left in stock!
            </div>
            <div className='page-header__steps'>
              <img src={steps} alt='steps' />
            </div>
          </div>
        </div>
      </header>

      <main className='page-main'>
        <section className='greetings'>
          <div className='container'>
            <div className='greetings-inner'>
              <h3>WAIT! You Have Qualified for a SPECIAL OFFER!</h3>
              <h1>{specialOffer.headline}</h1>
              <h2>
                Add this to your order and <b>get a {discount}% discount</b>
              </h2>
            </div>
          </div>
        </section>

        <section className='product-info'>
          <div className='container'>
            <div className='product-info-inner'>
              {/* left */}
              <div className='product-info-left'>
                <div className='product-info__header'>
                  <h3>{specialOffer.productName}</h3>
                  <div className='product-info__stars'>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    {specialOffer.delivered || '18,500'} delivered
                  </div>
                </div>

                <ul className='product-info__benefits'>
                  {specialOffer.benefits.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                {mergedProductsData.length > 1 ? (
                  <ProductSelector
                    offer={offer}
                    specialOffer={specialOffer}
                    products={mergedProductsData}
                    selectedProduct={selectedProduct}
                    onSelect={handleProductSelect}
                  />
                ) : (
                  <div className='product-info-prices'>
                    <div className='product-info-prices__msrp'>
                      <p>Regular Price</p>
                      <b>{getPriceWithCurrency(selectedProduct.oldPrice, offer?.locale, offer?.currency)}</b>
                      <s>+ {getPriceWithCurrency(8.95, offer?.locale, offer?.currency)} SHIPPING</s>
                    </div>
                    <div className='product-info-prices__offer'>
                      <p>
                        Your Price <span>({discount}% off)</span>
                      </p>
                      <b>{getPriceWithCurrency(selectedProduct.price, offer?.locale, offer?.currency)}</b>
                      <span>+ FREE SHIPPING</span>
                    </div>
                  </div>
                )}

                <div
                  className='product-info-cta js-yes-button animate__animated animate__pulse animate__infinite'
                  onClick={() => onSubmit(selectedProduct)}
                >
                  Yes! Add To My Order!
                </div>

                <div className='ships-info'>
                  {specialOffer.shippingText ? (
                    <p dangerouslySetInnerHTML={{ __html: specialOffer.shippingText }}></p>
                  ) : (
                    <>
                      <img src={usps} alt='usps' />
                      <p>
                        All orders ship from the USA 🇺🇸 via USPS within 1 business day. A tracking number will
                        be issued to your email.
                      </p>
                    </>
                  )}
                </div>

                <div className='product-right-guarantee product-right-guarantee--mob'>
                  <p>Guaranteed Safe Checkout</p>
                  <img src={guarantee} alt='guarantee' />
                </div>
              </div>
              {/* end left */}

              {/* right */}
              <div className='product-info-right'>
                <div className='product-right-images'>
                  <Slider
                    {...gallerySettings}
                    asNavFor={nav2}
                    ref={(slider1) => setNav1(slider1)}
                    className='product-info-gallery js-gallery'
                  >
                    {specialOffer.gallery.map((item, index) => (
                      <div className='product-info-gallery__img' key={index}>
                        {item.type === 'image' ? (
                          <img src={item.source} alt='' />
                        ) : (
                          <video
                            playsInline
                            autoPlay
                            muted
                            poster={item.thumbnail}
                            controls
                            src={item.source}
                          ></video>
                        )}
                      </div>
                    ))}
                  </Slider>

                  <Slider
                    {...thumbsSettings}
                    asNavFor={nav1}
                    ref={(slider2) => setNav2(slider2)}
                    className='product-info-gallery-thumbs js-thumbs'
                  >
                    {specialOffer.gallery.map((item, index) => (
                      <div className='product-info-gallery-thumbs__img' key={index}>
                        <img src={item.thumbnail} alt='thumbnail' />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className='product-right-guarantee product-right-guarantee--desk'>
                  <p>Guaranteed Safe Checkout</p>
                  <img src={guarantee} alt='guarantee' />
                </div>
              </div>
              {/* end right */}
            </div>
          </div>
        </section>

        <section className='no-button-wrapper'>
          <button onClick={() => onSubmit()} className='no-button'>
            No, I Don’t Want This Deal
          </button>
        </section>

        <i className='far fa-chevron-double-down animate__animated animate__fadeInDown animate__infinite animate__slow'></i>
      </main>
    </>
  );
}
